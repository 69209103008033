/* global $ */
/**
 * --------------------------------------------------------------------------
 * CoreUI Boostrap Admin Template (4.3.0): datatables.js
 * License (https://coreui.io/pro/license)
 * --------------------------------------------------------------------------
 */
$(document).ready(() => {
  $('.datatable').DataTable()
  $('.datatable').attr('style', 'border-collapse: collapse !important')
})
